module Shared {
    export class CS01ConfirmationDetails {
        public memberId: number;
        public periodend: Date;
        public duedate: Date;
        public confirmationrequired: Date;
        public response: string;
        public responseUser: string;
        public amendmentdetails: string = '';
    }
}
