/// <reference path="../../_all.ts" />
/// <reference path="../../shared/models/CS01Confirmation.ts" />
/// <reference path="../models/CompanyDetails.ts" />

module Shared {
    export interface ICS01ConfirmationService {
        GetNextIfOpen(): ng.IPromise<CS01ConfirmationDetails>;
        GetCompanyDetails(): ng.IPromise<CompanyDetails>;
        SaveResponse(CS01ConfirmationStatus: CS01ConfirmationDetails): ng.IPromise<boolean>;
    }

    export class CS01ConfirmationService implements ICS01ConfirmationService {
        static $inject = ['$injector', 'shared.config'];

        public serviceBase: string;
        public $http: ng.IHttpService;

        constructor(
            private $injector: ng.auto.IInjectorService,
            private sharedConfig: any
        ) {
            this.serviceBase = sharedConfig.baseApiUrl;
        }

        GetNextIfOpen = (): ng.IPromise<CS01ConfirmationDetails> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/CS01Confirmation/GetNextIfOpen";

            return this.$http.get(url)
                .then((response: ng.IHttpPromiseCallbackArg<CS01ConfirmationDetails>): CS01ConfirmationDetails => {
                    return response.data;
                });
        }

        GetCompanyDetails = (): ng.IPromise<CompanyDetails> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/CS01Confirmation/GetCompanyDetails";

            return this.$http.get(url)
                .then((response: ng.IHttpPromiseCallbackArg<CompanyDetails>): CompanyDetails => {
                    return response.data;
                });
        }

        SaveResponse = (CS01ConfirmationDetails: CS01ConfirmationDetails): ng.IPromise<boolean> => {
            this.$http = this.$http || this.$injector.get<ng.IHttpService>("$http");
            const url: string = this.serviceBase + "api/CS01Confirmation/Save";

            const request: CS01ConfirmationDetails = CS01ConfirmationDetails;

            return this.$http.post(url, request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }
    }
}

angular
    .module('app.shared')
    .service('cs01ConfirmationSrv', Shared.CS01ConfirmationService);