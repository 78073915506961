/// <reference path="../../_all.ts"/>

module Shared {
    export interface IBrooksonNavigation {
        account: IBrooksonNavigationAccount;
        bankLink: IBrooksonNavigationBankLink;
        dashboard: IBrooksonNavigationDashboard;
        documentStorage: IBrooksonNavigationDocumentStorage;
        employmentStatus: IBrooksonNavigationEmploymentStatus;
        expenses: IBrooksonNavigationEmploymentExpenses;
        home: IBrooksonNavigationHome;
        assignments: IBrooksonNavigationAssignments;
        invoicing: IBrooksonNavigationInvoicing;
        knowledgeBase: IBrooksonNavigationKnowledgeBase;
        login: IBrooksonNavigationLogin;
        moneyManager: IBrooksonNavigationMoneyManager;
        personalFinance: IBrooksonNavigationPersonalFinance;
        financialServices: IBrooksonNavigationFinancialServices;
        personalTax: IBrooksonNavigationPersonalTax;
        taskPlanner: IBrooksonNavigationTaskPlanner;
        sme: IBrooksonNavigationSme;
        employeeZone: IBrooksonNavigationEmployeeZone;
        financialCenter: IBrooksonNavigationFinancialCenter;
        advisory: IBrooksonNavigationAdvisory;
        selectServiceLine: IBrooksonSelectServiceLine;
        /*jobsBoard: IBrooksonNavigationJobsBoard;*/
    }

    export interface IBrooksonNavigationEmployeeZone {
        holidays(): void;
        payslips(): void;
    }

    export interface IBrooksonNavigationFinancialCenter {
        main(): void;
    }

    export interface IBrooksonNavigationSme {
        holidays(): void;
    }
    
    export interface IBrooksonNavigationAdvisory {
        advisory(): void;
    }

    //export interface IBrooksonNavigationJobsBoard {
    //    jobsBoard(): void;
    //}

    export interface IBrooksonNavigationWorkFlex {
        workFlex(): void;
    }

    export interface IBrooksonNavigationAccount {
        main(): void;
    }

    export interface IBrooksonNavigationBankLink {
        main(): void;
    }

    export interface IBrooksonSelectServiceLine {
        main(): void;
    }

    export interface IBrooksonNavigationDashboard {
        main(): void;
    }

    export interface IBrooksonNavigationDocumentStorage {
        main(): void;
        recentlyAdded(): void;
        favourites(): void;
        brooksonSharedDrive(): void;
        folder(): void;
    }

    export interface IBrooksonNavigationEmploymentStatus {
        main(returnUrl?: string, returnText?: string): void;
        ir35Assignment(assignmentId: string): void;
    }

    export interface IBrooksonNavigationEmploymentExpenses {
        main(): void;
        business(): void;
        expenseDetails(): void;
        addExpense(): void;
        addMileage(): void;
        addReceipt(): void;
        addAllowance(): void;
        editExpense(): void;
        editMileage(): void;
        editReceipt(): void;
        editAllowance(): void;
        expenseReport(): void;
        expensesReceiptBank(): void;
    }

    export interface IBrooksonNavigationHome {
        logout(): void;
    }

    export interface IBrooksonNavigationAssignments {
        main(): void;
        summary(assignmentId?: string): void;
        addAssignment(): void;
        editAssignment(assignmentId?: string): void;
    }

    export interface IBrooksonNavigationInvoicing {
        main(): void;
        raiseInvoice(id?: number): void;
        createInvoice(id?: number): void;
    };

    export interface IBrooksonNavigationKnowledgeBase {
        main(): void;
    };

    export interface IBrooksonNavigationLogin {
        main(): void;
        superuser(): void;
        serviceline(): void;
        registration(): void;
        passwordReset(): void;
        forgottenPassword(): void;
        shareholder(): void;
    };

    export interface IBrooksonNavigationMoneyManager {
        main(): void;
        profitLoss(): void;
        balanceSheet(): void;
        history(): void;
        transactions(): void;
    };

    export interface IBrooksonNavigationPersonalFinance {
        main(): void;
        riskProfile(): void;
        retirementPlanner(): void;
    };

    export interface IBrooksonNavigationFinancialServices {
        main(): void;
    };


    export interface IBrooksonNavigationPersonalTax {
        main(): void;
        planner(): void;
        tracker(): void;
        hrmcersonalAccountBalance(): void;
        selfAssessment(): void;
        selfAssessmentTAndCs(): void;
    };

    export interface IBrooksonNavigationTaskPlanner {
        main(): void;
        calendar(): void;
        calendarSelectedAction(): void;
        selectedAction(actionID: string): void;
        selectedActionTask(actionID: string, taskID: string): void;
    };
}

(() => {
    'use strict';

    angular
        .module('app.shared')
        .factory('brookson.navigation', brooksonNavigation);

    brooksonNavigation.$inject = ['$window'];

    function brooksonNavigation($window) {
        var account = {
            main: () => {
                $window.location.href = "/Account";
            }
        };

        var bankLink = {
            main: () => {
                $window.location.href = "/BankLink";
            }
        };

        var advisory = {
            main: () => {
                $window.location.href = "/Advisory";
            }
        };

        var selectServiceLine = {
            main: () => {
                $window.location.href = "/SelectServiceLine";
            }
        };

        //var jobsBoard = {
        //    main: () => {
        //        $window.location.href = "/JobsBoard";
        //    }
        //};

        var workFlex = {
            main: () => {
                $window.location.href = "/WorkFlex";
            },
            umbrella: () => {
                $window.location.href = "/WorkFlex/Umbrella";
            },
            personalServiceCompany: () => {
                $window.location.href = "/WorkFlex/PersonalServiceCompany";
            }
        };

        var dashboard = {
            main: () => {
                $window.location.href = "/Dashboard";
            }
        };

        var documentStorage = {
            main: () => {
                $window.location.href = "/DocumentStorage";
            },
            recentlyAdded: () => {
                $window.location.href = "/DocumentStorage/RecentlyAdded";
            },
            favourites: () => {
                $window.location.href = "/DocumentStorage/Favourites";
            },
            brooksonSharedDrive: (sharedDriveId) => {
                $window.location.href = "/DocumentStorage/BrooksonSharedDrive" + sharedDriveId;
            },
            folder: (folderId) => {
                $window.location.href = "DocumentStorage/" + folderId;
            },
            paySlipFolder: () => {
                $window.location.href = "DocumentStorage/2/";
            }
        };

        var employeeZone = {
            holidays: () => {
                $window.location.href = "/EmployeeZone/EmployeeHolidays";
            },
            payslips: () => {
                $window.location.href = "/EmployeeZone/Payslips";
            }
        };

        var employmentStatus = {
            main: (returnUrl?: string, returnText?: string) => {
                if (!returnUrl && !returnText) $window.location.href = "/EmploymentStatus";

                $window.location.href = `/EmploymentStatus?returnUrl=${returnUrl}&returnText=${returnText}`;
            },
            ir35Assignment: (assignmentId: string) => {
                $window.location.href = "/EmploymentStatus/" + assignmentId;
            }
        };

        var expenses = {
            main: () => {
                $window.location.href = "/Expenses/Personal";
            },
            business: () => {
                $window.location.href = "/Expenses/Business";
            },
            expenseDetails: () => {
                $window.location.href = "/Expenses/ExpenseDetails";
            },
            addExpense: () => {
                $window.location.href = "/AddExpense";
            },
            addMileage: () => {
                $window.location.href = "/AddMileage";
            },
            addReceipt: () => {
                $window.location.href = "/AddReceipt";
            },
            addAllowance: () => {
                $window.location.href = "/AddAllowance";
            },
            editExpense: () => {
                $window.location.href = "/EditExpense";
            },
            editMileage: () => {
                $window.location.href = "/EditMileage";
            },
            editReceipt: () => {
                $window.location.href = "/EditReceipt";
            },
            editAllowance: () => {
                $window.location.href = "/EditAllowance";
            },
            expenseReport: (id) => {
                $window.location.href = "/Expenses/ExpenseReport/" + id;
            },
            expensesReceiptBank: () => {
                $window.location.href = "/Expenses/ReceiptBank";
            }
        };

        var financialCenter = {
            main: () => {
                $window.location.href = "/FinancialCenter";
            }
        };

        var home = {
            logout: () => {
                $window.location.href = "/Home/LogOut";
            }
        };

        var assignments = {
            main: () => {
                $window.location.href = "/Assignments";
            },
            summary: (assignmentId?: string) => {
                let url = "/Assignments/Summary";
                url += assignmentId ? '/' + assignmentId : '';
                $window.location.href = url;
            },
            addAssignment: () => {
                $window.location.href = "/Assignments/Add-Assignment";
            },
            editAssignment: (assignmentId?: string) => {
                let url = "/Assignments/Edit-Assignment";
                url += assignmentId ? '/' + assignmentId : '';
                $window.location.href = url;
            }
        };

        var invoicing = {
            main: () => {
                $window.location.href = "/Invoicing";
            },
            raiseInvoice: (id?: number) => {
                let url = "/Invoicing/Raise-Invoice";
                url += id ? '/' + id : '';
                $window.location.href = url;
            },
            createInvoice: (id?: number) => {
                let url = "/Invoicing/create-invoice";
                url += id ? '/' + id : '';
                $window.location.href = url;
            },
            newInvoice: () => {
                let url = "/Invoicing/Raise-Invoice/";
                $window.location.href = url;
            },
        };

        var knowledgeBase = {
            main: () => {
                $window.location.href = "/Dashboard/KnowledgeBase";
            }
        };

        var login = {
            main: () => {
                $window.location.href = "/Login";
            },
            superuser: () => {
                $window.location.href = "/Login/Super";
            },
            serviceline: () => {
                $window.location.href = "/Login/ServiceLine";
            },
            registration: () => {
                $window.location.href = "/Login/Registration";
            },
            passwordReset: (id) => {
                $window.location.href = "/Login/PasswordReset/" + id;
            },
            forgottenPassword: () => {
                $window.location.href = "/Login/ForgottenPassword";
            },
            shareholder: () => {
                $window.location.href = "/login/shareholder";
            }
        };

        var moneyManager = {
            main: () => {
                $window.location.href = "/MoneyManager";
            },
            profitLoss: () => {
                $window.location.href = "/MoneyManager/ProfitLoss";
            },
            balanceSheet: () => {
                $window.location.href = "/MoneyManager/BalanceSheet";
            },
            history: (week) => {
                $window.location.href = "/MoneyManager/History/" + week;
            },
            transactions: () => {
                $window.location.href = "/MoneyManager/Transactions";
            }
        };

        var personalFinance = {
            main: () => {
                $window.location.href = "/PersonalFinance";
            },
            riskProfile: () => {
                $window.location.href = "/PersonalFinance/RiskProfile";
            },
            retirementPlanner: () => {
                $window.location.href = "/PersonalFinance/RetirementPlanner";
            }
        };

        var financialServices = { 
            main: () => {
                $window.location.href = "/FinancialServices";
            }
        };

        var personalTax = {
            main: () => {
                $window.location.href = "/PersonalTax";
            },
            planner: () => {
                $window.location.href = "/TaskPlanner/saPersonalTax";
            },
            tracker: () => {
                $window.location.href = "/PersonalTax/Tracker";
            },
            hrmcersonalAccountBalance: () => {
                $window.location.href = "/PersonalTax/HMRCPersonalAccountBalance";
            },
            selfAssessment: () => {
                $window.location.href = "/PersonalTax/SelfAssessment";
            }            ,
            selfAssessmentTAndCs: () => {
                $window.location.href = "/PersonalTax/SelfAssessment/TermsAndConditions";
            }
        };

        var taskPlanner = {
            main: () => {
                $window.location.href = "/TaskPlanner";
            },
            calendar: () => {
                $window.location.href = "/Calendar/calendar";
            },
            calendarSelectedAction: (actionID) => {
                $window.location.href = "/Calendar/calendar/" + _.camelCase(actionID);
            },
            selectedAction: (actionID) => {
                $window.location.href = "/TaskPlanner/" + _.camelCase(actionID);
            },
            selectedActionTask: (actionID, taskID) => {
                $window.location.href = "/TaskPlanner/" + _.camelCase(actionID) + "/" + taskID;
            }
        };

        var sme = {
            holidays: () => {
                $window.location.href = "/Sme/Holidays";
            }
        };

        var openBankingRedirect = {
            holidays: () => {
                $window.location.href = "/OpenBankingRedirect";
            }
        };

        var service = {
            account: account,
            bankLink: bankLink,
            dashboard: dashboard,
            documentStorage: documentStorage,
            employmentStatus: employmentStatus,
            expenses: expenses,
            home: home,
            assignments: assignments,
            invoicing: invoicing,
            knowledgeBase: knowledgeBase,
            login: login,
            moneyManager: moneyManager,
            personalFinance: personalFinance,
            financialServices: financialServices,
            personalTax: personalTax,
            taskPlanner: taskPlanner,
            openBankingRedirect: openBankingRedirect,
            sme: sme,
            employeeZone: employeeZone,
            financialCenter: financialCenter,
            advisory: advisory,
            selectServiceLine: selectServiceLine,
/*            jobsBoard: jobsBoard,*/
            workFlex: workFlex
        };

        return service;
    }
})();