/// <reference path="../../../_all.ts" />
/// <reference path="./../../services/brookson.shared.swap-user-service-line.ts"/>

module Shared {

    export interface AppSettings extends ng.IWindowService {
        appSettings: any;
    }

    export interface StateParamsWithLocation extends ng.ui.IStateParamsService {
        hideBreadcrumb: boolean;
    }

    export class BrooksonSharedNavigation implements ng.IComponentOptions {
        public controller: Function = BrooksonSharedNavigationController;
        public templateUrl: string = "src/app/shared/views/navigation.html";
    }

    class BrooksonSharedNavigationController {
        static $inject = [
            "$window", "$state", "$rootScope", "$location", "$stateParams",
            "memberSrv", "dashboardService", "brookson.factory.privacy",
            "brooksonFactoriesIntro", "screenSize", "appSettings", "member.enums",
            "swapServiceLineSrv", "serviceLineSrv"
        ];

        public pscOpen: boolean = false;
        public pfOpen: boolean = false;
        public ptOpen:  boolean = false;
        public managerZoneOpen: boolean = false;
        public employeeZoneOpen: boolean = false;
        public window: AppSettings;
        public member: MemberModel;
        public togglePrivacy: any;
        public serviceLines: Array<Shared.ServiceLine>;
        public hasAdvisorySection: boolean;
        public hasAdvisorySectionUmb: boolean;
        public memberBusinessType: string;

        constructor(
            private $window: AppSettings,
            private $state: ng.ui.IStateService,
            private $rootScope: ng.IRootScopeService,
            private $location: ng.ILocationService,
            private stateParams: StateParamsWithLocation,
            private memberSrv: Shared.IMemberService,
            private dashboardService: Shared.IDashboardService,
            private privacyFactory: Shared.IBrooksonFactoryPrivacy,
            private introFactory: Shared.IBrooksonFactoriesIntro,
            private screenSize: any,
            private appSettings: Shared.AppSettings,
            private memberEnums: Shared.IMemberEnums,
            public swapServiceLineSrv: Shared.SwapUserServiceLineService,
            private serviceLineSrv: Shared.IServiceLineService
        ) {
            this.window = $window;
            this.member = this.getMember();
            this.togglePrivacy = this.privacyFactory.togglePrivacy;
            this.memberBusinessType = this.memberSrv.getMemberBusinessType();

            let umbrellaAssignmentSdcStatusChangeListener = this.$rootScope.$on("UmbrellaAssignmentSDCStatusChange", (event, memberSdcStatus) => {
                if (this.window.appSettings.pscExpensesEnabledGlobally) {
                    if (!this.member.serviceLines[0].isEligible) {   // Do not add expenses if the customer is a leaver
                        if (memberSdcStatus.SDCStatus === 4) { // If an assignment has passed SDC checks then enable the expenses menu option.
                            this.window.appSettings.pscExpensesEnabled = true;
                            return;
                        }
                    }
                    this.window.appSettings.pscExpensesEnabled = false;
                }
            });

            this.$rootScope.$on("$destroy", () => {
                umbrellaAssignmentSdcStatusChangeListener(); // Deregister umbrellaAssignmentSDCStatusChangeListener on destroy
            });            

            this.getMemberBusinessTypes();

            memberSrv.getHasAdvisoryKeyInformation().then((response): void => {
                this.hasAdvisorySection = response;
            });
            
            memberSrv.getHasAdvisoryKeyInformationUmbrella().then((response): void => {
                this.hasAdvisorySectionUmb = response;
            });
        }



        public $onInit = (): void => {
            this.checkNavigationDropdowns();
            this.$rootScope.$on("$stateChangeSuccess", () => { this.checkNavigationDropdowns(); });
        }

        getMemberBusinessTypes = (): void => {
                this.member.ltdCustomerReference = this.$window.appSettings.ltdCustomerReference;
                this.member.hasLtdBusinessType = this.$window.appSettings.hasLtdBusinessType;
                this.member.umbCustomerReference = this.$window.appSettings.umbCustomerReference;
                this.member.hasUmbBusinessType = this.$window.appSettings.hasUmbBusinessType;
                this.member.hasMulipleLtdCompanies = this.$window.appSettings.hasMulipleLtdCompanies;
        }

        public showHamburger = (): boolean => {
            return this.screenSize.is('xs');
        }
        
        public changeUserServiceLine = (): void => {
            this.swapServiceLineSrv.changeSelectedServiceLine(this.member.umbCustomerReference);
        }

        public showDashboardButtons = (): boolean => {
            return this.$state.includes("*.dashboard");
        }

        public deleteDashboards = (): void => {
            this.dashboardService.showDelete().then((dashboards) => {
                if (dashboards.length) {
                    this.dashboardService.deleteDashboards(dashboards);
                }
            });
        }

        public setCustomiseOn = (value: boolean): void => {
            this.dashboardService.customiseOn = value;
        }

        public getCustomiseOn = (): boolean => {
            return this.dashboardService.customiseOn;
        }

        public deleteEnabled = (): boolean => {
            return _.some(this.dashboardService.userDashboards, { 'isBrookson': false });
        }

        public getMember = (): MemberModel => {
            return this.memberSrv.getMember();
        }

        public checkNavigationDropdowns = (): void => {
            this.checkNavigationDropdown("personalTax");
            this.checkNavigationDropdown("personalFinance");
            this.checkNavigationDropdown("psc");
            this.checkNavigationDropdown("financialCenter");
            this.checkNavigationDropdown("sme");
            this.checkNavigationDropdown("employeeSme");
            this.checkNavigationDropdown("smePersonalTax");
            this.checkNavigationDropdown("advisory");
            /*this.checkNavigationDropdown("jobsboard");*/
            // this.checkNavigationDropdown("knowledgeBase");
            this.checkNavigationDropdown("flexUmbrella");
            this.checkNavigationDropdown("flexCompany");
            this.checkNavigationDropdown("financialServices");
            
        }

        public togglePSC = (value: boolean): void => {
            if (!this.showHamburger())
                this.pscOpen = value;
        }

        public togglePF = (value: boolean): void => {
            if (!this.showHamburger())
                this.pfOpen = value;
        }

        public togglePT = (value: boolean): void => {
            if (!this.showHamburger())
                this.ptOpen = value;
        }

        public toggleManagerZone = (value: boolean): void => {
            this.managerZoneOpen = value;
        }

        public toggleEmployeeZone = (value: boolean): void => {
            this.employeeZoneOpen = value;
        }

        public checkNavigationDropdown = (id: string): void => {
            let dropdown = angular.element(`#${id}`);
            let dropdownLinks = dropdown.find(".dropdownmenu a");

            dropdownLinks = dropdownLinks.length ? dropdownLinks : dropdown.find("a");
            dropdown.removeClass("current");
            dropdownLinks.removeClass("active");

            //Remove
            dropdown.removeClass("active");

            if(this.$location.path().indexOf("StarterZone/WorkFlex") === 1 && id === "flexCompany")
            {
                dropdown.addClass("current");
                //Remove
                dropdown.addClass("active");
            }

            // Ensure that the document storage link is highlighted if any of the document storage states are activated
            if (this.$location.path().indexOf("DocumentStorage") !== -1 &&
                this.$location.path().indexOf("DocumentStorage/4") !== 1 &&
                id === "psc") {
                dropdown.addClass("current");
                dropdown.find('a[href*="/DocumentStorage/RecentlyAdded"]').addClass("current");
                //Remove
                dropdown.addClass("active");
                dropdown.find('a[href*="/DocumentStorage/RecentlyAdded"]').addClass("active");
            } else {
                _.forEach(dropdownLinks,
                    (link: HTMLAnchorElement) => {
                        var angularLink = angular.element(link);
                        var ignorePathsAttribute = link.attributes["ignore-paths"];
                        var ignorePaths = ignorePathsAttribute ? _.split(ignorePathsAttribute.value, ",") : [];
                        var pathShouldBeIgnored = _.some(ignorePaths, value => _.startsWith(this.$location.path(), value));

                        if (_.startsWith(this.$location.path(), link.pathname) && !pathShouldBeIgnored) {
                            angularLink.addClass("active");
                            dropdown.addClass("current");
                            //Remove
                            dropdown.addClass("active");
                        }
                    }
                );
            }
        }

        public hideBreadcrumb = (): boolean => {
            return (this.stateParams.hideBreadcrumb && this.stateParams.hideBreadcrumb === true);
        }

        public isActive = (location: string, explicit: string): string => {
            const activeClass = "current";

            if (explicit) {
                return location === this.$location.path() ? activeClass : "";
            }

            return _.startsWith(this.$location.path(), location) ? "active" : "";
        }

        public isActivePsc = (): string => {
            return "current";
        }

    }
}

angular
    .module('app.shared')
    .component('brooksonSharedNavigation', new Shared.BrooksonSharedNavigation());