module Shared {
    export class CompanyDetails {
        public companyname: string;
        public line1: string;
        public line2: string;
        public city: string;
        public county: string;
        public postcode: string;
    }
}
